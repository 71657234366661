import Axios from "axios";
import { url } from "../../global";
import moment from "moment";

export const fetchTrace = (filter) => {
  return async (dispatch, getState) => {
    dispatch({
      type: "FETCHING_START",
    });
    const traces = await Axios.get(`${url}/trace`, {
      params: {
        page: filter.page,
        perpage: filter.perpage,
        keyword: filter.keyword,
        ordering: filter.ordering,
        start_date: filter.start_date
          ? moment(filter.start_date).format("YYYY-MM-DD")
          : null,
        end_date: filter.end_date
          ? moment(filter.end_date)
              .add(1, "day")
              .format("YYYY-MM-DD")
          : null,
        status: filter.status ? "1" : "0",
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    });
    console.log(traces.data.data, "data");

    dispatch({
      type: "FETCH_TRACE",
      payload: traces.data.data,
    });

    dispatch({
      type: "FETCHING_FINISH",
    });
  };
};


