const initState = {
	fetching: false,
	fetched: false,
	error: null,
	payload: {},
	saved: false,
	message: null,
	kanban: {},
	isDeleted: false,
};

const kanbanReducer = (state = initState, action) => {
	switch (action.type) {
		case 'SAVE_KANBAN_PENDING' : 
			return {
				...state,
				fetching: true,
				saved: false
			};
		case 'SAVE_KANBAN_REJECTED' :
			if (action.payload.response.status === 401) {
				sessionStorage.removeItem('token');
			}
			
			return {
				...state,
				error: action.payload.response,
				fetching: false,
				fetched: false,
				saved: false
			};
		case 'SAVE_KANBAN_FULFILLED':
			return {
				...state,
				fetching: false,
				fetched: true,
				message: action.payload.data.message,
				error: null,
				saved: true
			};
    default :
        return state
    }
}

export default kanbanReducer 