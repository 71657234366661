import React from 'react';
import { Container, Row, Col, Card, CardBody, FormCheckbox, FormRadio } from 'shards-react';
import PageTitle from '../components/common/PageTitle';
import { Redirect } from 'react-router-dom';
import { appName, url, urlb } from '../global';
import { Helmet } from 'react-helmet';
import ScrollToTop from '../components/layout/ScrollToTop';
import { withToastManager } from 'react-toast-notifications';
import { fetchQrcode, deleteQrcode } from '../store/actions/qrcodeAction';
import {connect} from 'react-redux';
import Loading from 'react-loading-bar';
import Error500 from './Error500';
import Table from '../components/table/Table';
import Modal from 'react-bootstrap4-modal';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import Error403 from './Error403';
import fileDownload from 'js-file-download'

class Qr extends React.Component {
	state = {
        search: null,
        page: 1,
        perpage: 10,
		keyword: null,
		alert: true,
		alertMsgBox: false,
		deleteIdQrcode: null,
		showMsgBox: false,
		isDeleted: false,
		ordering: {
            type: 'part_number',
            sort: 'asc'
		},
		modal: false,
		file_import: '',
		import: 'Choose file...',
		importing: false,
		checked: [],
		download: false,
		modalPrint: false,
		template: '1',
		downloadLoading: false,
		printed: false,
	}
	
    handleSorting = (e) => {
        const type = e.target.id;
        const sort = this.state.ordering.sort;
        this.setState({
			...this.state,
            ordering: {
                type: type,
                sort: sort === 'asc' ? 'desc' : 'asc'
            }
        });
    }

    handleChangeKeyword = (e) => {
		this.setState({
			...this.state,
			[e.target.id]: e.target.value
		});
	}

	handleSubmitKeyword = (e) => {
		e.preventDefault();
		this.props.fetchQrcode(this.state);
	}

	handleClickPage = (e) => {
        this.setState({
            ...this.state,
            page: e
        });
    }

    hanldeChangePage  = (e) => {
        this.setState({
            ...this.state,
            perpage: e.target.value
        });
    }
    
    handleClickDelete = (id) => {
		this.setState({
			...this.state,
			deleteIdQrcode: id,
			showMsgBox: true
		});
	}
	
	handleClickYes = () => {

		this.setState({
			...this.state,
			alertMsgBox: true,
			showMsgBox: false,
			isDeleted: true
		});

		this.props.deleteQrcode(this.state.deleteIdQrcode);
	}

	handleClickNo = () => {
		this.setState({
			...this.state,
			showMsgBox: false,
			deleteIdQrcode: null
		});
	}

	handlePopupPrint = () => {
		const filter = this.state.checked.map(item => { return item.isChecked })
		
		if (filter.includes(true)) {
			this.setState({
				...this.state,
				modalPrint: true
			})
		} else {
			const { toastManager } = this.props;
			toastManager.add('Please select at least one item!', {
				appearance: 'error',
				autoDismiss: true
			});
		}
	}

    componentWillUpdate(nextProps, nextState) {
        if (this.state.page !== nextState.page) {
            this.props.fetchQrcode(nextState);
        }

        if (this.state.perpage !== nextState.perpage) {
            this.props.fetchQrcode(nextState);
		}
		
		if (this.state.ordering !== nextState.ordering) {
			this.props.fetchQrcode(nextState);
		}

		if (this.state.printed !== nextState.printed) {
			this.props.fetchQrcode(nextState);
		}
    }
    
    componentDidUpdate = (prevProps, prevState) => {

        if (prevProps.error !== this.props.error) {
            if (!this.props.fetched) {
                if (this.props.error) {
                    const { toastManager } = this.props;
                    toastManager.add(this.props.error.data.message, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                }
            }
		}
		
		if (prevProps.isDeleted !== this.props.isDeleted) {
			if (this.props.isDeleted) {
				const { toastManager } = this.props;
				toastManager.add(this.props.message, {
					appearance: 'success',
					autoDismiss: true
				});
				this.props.fetchQrcode(this.state);
			}
		}

		if (prevProps.payload !== this.props.payload) {
			const data = this.props.payload.data && this.props.payload.data.data.map(item => {
				return {
					id: item._id,
					part_number: item.part_number,
					description: item.description,
					qty: item.qty,
					lot_id: item.lot_id,
					isChecked: false,
					copies: item.copies
				}
			})
			
			this.setState({
				...this.state,
				checked: data
			})

		}
	}
	
	handleModal = () => {
		this.setState({
			...this.state,
			modal: true
		})
	}

	modalBackdropClicked = () => {
		this.setState({
			...this.state,
			modal: false,
			modalPrint: false
		});
	}

    componentDidMount = () => {
		this.props.fetchQrcode(this.state)
	}

	handleChangeFileImport = (e) => {
		
		if (e.target.files.length > 0) {
			const file = e.target.files[0]
			this.setState({
				...this.state,
				import: file.name,
				file_import: file 
			})
		}

	}

	handleImport = () => {
		const { toastManager } = this.props;
		
		this.setState({
			...this.state,
			importing: true
		});

		const fd = new FormData;
		fd.append('file', this.state.file_import);

		Axios.post(`${url}/qr-code/import`, fd,
		{
			headers: {
				Authorization: `Bearer ${sessionStorage.getItem('token')}`,
				'Conten-Type': 'multipart/form-data'
			}
		}).then(res => {
			
			this.setState({
				...this.state,
				modal: false,
				importing: false,
				file_import: '',
				import: 'Choose file...'
			});

			toastManager.add(res.data.message, {
				appearance: 'success',
				autoDismiss: true
			});

			this.fileImport.value = ''
			this.props.fetchQrcode(this.state);

		})
		.catch(error => {
			
			this.setState({
				...this.state,
				modal: false,
				importing: false,
				file_import: '',
				import: 'Choose file...'
			});

			this.fileImport.value = ''

			toastManager.add(error.response.data.message, {
				appearance: 'error',
				autoDismiss: true
			});

		});
	}

	handleCheckBox = (event) => {
		
		let checked = this.state.checked

		checked.forEach(checked => {
			if (checked.id === event.target.value)
				checked.isChecked =  event.target.checked
		})

		this.setState({
			...this.state,
			checked: checked
		})
	}

	handleCopies = (event) => {

		const { toastManager } = this.props;

		Axios.put(`${url}/qr-code/${event.target.dataset.value}`, {
			copies: event.target.value
		}, {
			headers: {
				Authorization: `Bearer ${sessionStorage.getItem('token')}`
			}
		}).then(res => {

			toastManager.add(res.data.message, {
				appearance: 'success',
				autoDismiss: true
			});

			this.props.fetchQrcode(this.state)

		}).catch(error => {

			toastManager.add(error.response.data.message, {
				appearance: 'error',
				autoDismiss: true
			});

		})

		let checked = this.state.checked

		checked.forEach(checked => {
			if (checked.id === event.target.dataset.value)
				checked.copies =  event.target.value
		})

		this.setState({
			...this.state,
			checked: checked
		})
	}

	handleCheckAll = () => {

		let checked = this.state.checked
    	checked.forEach(check => check.isChecked = true) 
    	this.setState({checked: checked})
		
	}

	handleUnCheckAll = () => {
		let checked = this.state.checked
    	checked.forEach(check => check.isChecked = false) 
    	this.setState({checked: checked})
	}

	handleToggleCheck = () => {
		
		let checked = this.state.checked

		checked.forEach(checked => {
			checked.isChecked =  checked.isChecked ? false : true
		})

    	this.setState({checked: checked})
	}

	handleChangeTemplate = (e) => {

		this.setState({
			...this.state,
			template: e.target.value
		})
	}

	handlePrint = async () => {
		const { toastManager } = this.props;
		this.setState({
			...this.state,
			download: true
		});

		await Axios.post(`${url}/qr-code/print`, {
			check: this.state.checked,
			template: this.state.template
		}, {
			responseType: 'blob',
			headers: {
				Authorization: `Bearer ${sessionStorage.getItem('token')}`,
			}
		}).then(response => {
			
			this.setState({
				...this.state,
				download: false
			});

			const file = new Blob([response.data], {type: 'application/pdf'});
			const fileURL = URL.createObjectURL(file);
			window.open(fileURL);

			this.setState({
				...this.state,
				modalPrint: false
			});

			this.props.fetchQrcode(this.state);
		  
		}).catch(error => {

			this.setState({
				...this.state,
				download: false
			});

			if (error.response) {
				
				toastManager.add(error.response.statusText, {
					appearance: 'error',
					autoDismiss: true
				});
			}

			
		})
	}


	handleDownloadTemplate = async () => {
		const { toastManager } = this.props;
		this.setState({
			...this.state,
			downloadLoading: true
		});

		await Axios.get(`${url}/qr-code/download-template`, {
			responseType: 'blob',
			headers: {
				Authorization: `Bearer ${sessionStorage.getItem('token')}`
			}
		}).then(response => {
			
			fileDownload(response.data, 'template_qr.xlsx');

			this.setState({
				...this.state,
				downloadLoading: false
			});


		}).catch(error => {

			this.setState({
				...this.state,
				downloadLoading: false
			});


			toastManager.add(error.response.data.message, {
				appearance: 'error',
				autoDismiss: true
			});

		})
	}

	handleChangeTab = (value) => {
		this.setState({
			...this.state,
			printed: value
		})
	}

	render() {
		const {payload, error, fetching} = this.props;

		if (!sessionStorage.getItem('token')) return <Redirect to="/login" />
		if (error && error.status === 500) return <Error500 message={error.data.message} />
		if (error && error.status === 403) return <Error403 message={error.data.message} />

		const {ordering} = this.state;
        const theads = [
            {name:'part_number', 'value': `Supplier Part Number`, sortable: true},
			{name:'description', 'value': 'Part Description', sortable: true},
			{name:'supplier_name', 'value': 'Supplier', sortable: true},
            {name:'plant_name', 'value': 'Plant', sortable: true},
            {name:'qty', 'value': 'Qty', sortable: true},
            {name:'uom', 'value': 'UoM', sortable: true},
            {name:'part_group', 'value': 'Part Group', sortable: true},
            {name:'type_part', 'value': 'Part Type', sortable: true},
            {name:'lot_id', 'value': 'Lot ID', sortable: true},
            {name:'packing_date', 'value': 'Production Date', sortable: true},
            {name:'coil_id', 'value': 'Coil ID', sortable: true},
			{name: 'reprint', value: 'Reprint', sortable: true },
			{name:'copies', 'value': 'Copies', sortable: true},
			{name:'options', 'value': '', sortable: false}
		];

		const qrcodes = payload.data && payload.data.data.map((qrcode, index) => {
			const checked = this.state.checked[index]
			return (
				<tr key={qrcode._id}>
					<td><FormCheckbox checked={checked && checked.isChecked ? true : false} value={qrcode._id} onChange={this.handleCheckBox}>{ qrcode.part_number }</FormCheckbox></td>
					<td>{ qrcode.description }</td>				
					<td>{ qrcode.supplier && qrcode.supplier.name  }</td>
					<td>{ qrcode.plant && qrcode.plant.code }</td>
					<td>{ qrcode.qty }</td>
					<td>{ qrcode.uom }</td>
					<td>{ qrcode.part_group }</td>
					<td>{ qrcode.type_part }</td>
					<td>{ qrcode.lot_id }</td>
					<td>{ qrcode.packing_date_formatted }</td>
					<td>{ qrcode.coil_id }</td>
					<td>{ qrcode.reprint }</td>
					<td style={{ width: '100px' }}><input data-value={qrcode._id} type="number" value={ qrcode.copies ? qrcode.copies : 1 } onChange={this.handleCopies} className="form-control" /></td>
					<td className="text-center">
						<div className="btn-group">
							<button type="button" className="btn btn-lg p-0 m-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<i className="mdi mdi-dots-vertical"></i>
							</button>
							<div className="dropdown-menu dropdown-menu-right">
								<Link to={`/qr-code/edit/${qrcode._id}`} className="dropdown-item text-success"><i className="mdi mdi-pencil mr-2"></i>Edit</Link>
								<button className="dropdown-item text-danger" type="button" onClick={() => this.handleClickDelete(qrcode._id)}><i className="mdi mdi-close mr-2"></i>Delete</button>
							</div>
						</div>
					</td>
            	</tr>
            );
		});


		return (
			<Container fluid className="main-content-container px-4">
				<Loading
					show={fetching}
					color="blue"
					showSpinner={false}
					/>
				<Helmet>
					<title>Qrcode | {appName} </title>
				</Helmet>
				<Row noGutters className="page-header py-4">
					<PageTitle sm="4" title="Qrcode"  className="text-sm-left" />
				</Row>
					<Modal visible={this.state.modalPrint} onClickBackdrop={this.modalBackdropClicked}>
						<div className="modal-header">
							<h5 className="modal-title">Print QR Codes</h5>
						</div>
						<div className="modal-body py-0 pt-2 px-4">
							<div className="row">
								<div className="form-group">
									<FormRadio onChange={this.handleChangeTemplate} name="template" value="1" checked={ this.state.template === '1' ? true : false}>Template 1</FormRadio>
									<FormRadio onChange={this.handleChangeTemplate} name="template" value="2" checked={ this.state.template === '2' ? true : false}>Template 2</FormRadio>
									<FormRadio onChange={this.handleChangeTemplate} name="template" value="3" checked={ this.state.template === '3' ? true : false}>Template 3</FormRadio>
								</div>
							</div>
						</div>
						<div className="modal-footer">
						<button type="button" className="btn btn-default" onClick={this.modalBackdropClicked}>
							Close
						</button>
						{
							this.state.download ? (
								<button className="btn btn-secondary btn-disabled" disabled>
									<i className="mdi mdi-loading mdi-spin"></i> Loading...
								</button>
							) : (
								<button className="btn btn-secondary" onClick={this.handlePrint}>
									<i className="mdi mdi-printer"></i> Print selected
								</button>
							)
						}
						</div>
					</Modal>


					<Modal visible={this.state.modal} onClickBackdrop={this.modalBackdropClicked}>
						<div className="modal-header">
							<h5 className="modal-title">Import qrcodes</h5>
						</div>
						<div className="modal-body py-0 pt-2 px-4">
							<div className="row">
								<div className="custom-file">
									<input
										id="import"
										type="file"
										className="custom-file-input"
										onChange={this.handleChangeFileImport} 
										accept=".xlsx, .xls, .csv"
										ref={ref => this.fileImport = ref}
									/>
									<label
										className="custom-file-label"
										htmlFor="customFile2"
										id="placeholderCustomFile2"
									>
										{this.state.import}
									</label>
								</div>
								<div className="mb-3">
									{
										this.state.downloadLoading ? (
											<button className="btn text-success btn-disabled" disabled onClick={this.handleDownloadTemplate}><i className="mdi mdi-loading mdi-spin mr-2"></i>Loading...</button>
										) : (
											<button className="btn text-success" onClick={this.handleDownloadTemplate}><i className="mdi mdi-download mr-2"></i>Download upload template</button>
										)
									}
									
									<div>
										<small>
											*) File format is xlsx, xlsx, or csv (semicolon separator ';')<br />
											*) Warning! existing data will be overwritted, be carefully! <br />
											*) Max file size 1MBs <br />
										</small>
									</div>
								</div>
							</div>
						</div>
						<div className="modal-footer">
						<button type="button" className="btn btn-default" onClick={this.modalBackdropClicked}>
							Close
						</button>
						{
							this.state.importing ? (
								<button type="button" className="btn btn-secondary btn-disabled" disabled>
									<i className="mdi mdi-loading mdi-spin"></i> Processing...
								</button>
							) : (
								<button type="button" className="btn btn-secondary" onClick={this.handleImport}>
									Import
								</button>
							)
						}
						
						</div>
					</Modal>
				<Row>
					{
						this.state.showMsgBox &&
						(
							<ScrollToTop>
								<div className="messagebox">
									<p className="mb-5">Are you sure want to delete this data?</p>
									<button className="btn btn-secondary mr-4" onClick={this.handleClickYes}>Yes</button>
									<button className="btn btn-default" onClick={this.handleClickNo}>No Cancel</button>
								</div>
								<div className="backdrop"></div>
							</ScrollToTop>
						)
					}
					<Col>
					<Card small className="mb-4">
							<CardBody className="p-0 pb-3">
						<div className="col-md-12 mt-4">
							<div className="row">
								<div className="col-md-8">
									<button className="btn btn-secondary" onClick={this.handleModal}>
										<i className="mdi mdi-upload" /> Import
									</button>
									<button className="btn btn-secondary mx-2" onClick={this.handleCheckAll}>
										<i className="mdi mdi-check-box-outline" /> Check all
									</button>
									<button className="btn btn-secondary" onClick={this.handleUnCheckAll}>
										<i className="mdi mdi-checkbox-blank-outline" /> Uncheck all
									</button>
									<button className="btn btn-secondary mx-2" onClick={this.handleToggleCheck}>
										<i className="mdi mdi-toggle-switch" /> Toggle Check
									</button>
									<button className="btn btn-secondary mx-2" onClick={this.handlePopupPrint}>
										<i className="mdi mdi-printer" /> Print selected
									</button>
									
								</div>
								<div className="col-md-4 text-right">
									<form onSubmit={this.handleSubmitKeyword}>
										<div className="input-group mb-3">
											<input
												id="keyword"
												type="text"
												className="form-control"
												placeholder=""
												aria-label="Example text with button addon"
												aria-describedby="button-addon1"
												onChange={this.handleChangeKeyword}
											/>
											<div className="input-group-prepend">
												<button
													className="btn btn-secondary"
													type="submit"
													id="button-addon1"
												>
													<i className="mdi mdi-magnify" /> Search{' '}
												</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div className="col-md-12 mt-3">

						<ul className="nav nav-tabs" id="myTab" role="tablist">
							<li className="nav-item">
								<a className="nav-link active" id="home-tab" data-toggle="tab" href="#unprinted" role="tab" aria-controls="unprinted" aria-selected="true" onClick={() => this.handleChangeTab(false)}>Unprinted</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" id="profile-tab" data-toggle="tab" href="#printed" role="tab" aria-controls="printed" aria-selected="false" onClick={() => this.handleChangeTab(true)}>Printed</a>
							</li>
							
						</ul>
						<div className="tab-content" id="myTabContent">
							<div className="tab-pane fade show active" id="unprinted" role="tabpanel" aria-labelledby="home-tab">
								<Table theads={theads} ordering={ordering} handleSorting={this.handleSorting}>
									{ 
										fetching ? 
										(
											<tr>
												<td className="text-center" colSpan="14">Loading...</td>
											</tr>
										)
										:
										payload.data && payload.data.data.length > 0 ? qrcodes : (
											<tr>
												<td className="text-center" colSpan="14">Data not found</td>
											</tr>
									) }
								</Table>	
							</div>
							<div className="tab-pane fade" id="printed" role="tabpanel" aria-labelledby="profile-tab">
								<Table theads={theads} ordering={ordering} handleSorting={this.handleSorting}>
									{ 
										fetching ? 
										(
											<tr>
												<td className="text-center" colSpan="14">Loading...</td>
											</tr>
										)
										:
										payload.data && payload.data.data.length > 0 ? qrcodes : (
											<tr>
												<td className="text-center" colSpan="14">Data not found</td>
											</tr>
									) }
								</Table>	
							</div>
						</div>

						</div>

						<div className="col-md-12 py-3">
							<div className="row">
								<div className="col-md-10">
									{ payload.data && payload.data.total > 1 && (
										<p>Showing { payload.data && payload.data.from.toLocaleString() } to { payload.data && payload.data.to.toLocaleString() } of { payload.data && payload.data.total.toLocaleString() } record(s)</p>

									)}

									{
										payload.data && payload.data.total > 1 && (
											<nav aria-label="Page navigation example">
												<ul className="pagination">

													{ payload.data.current_page > 1 && <li key="prev" className="page-item"><button onClick={this.handleClickPage.bind(null, payload.data.current_page - 1)} className="page-link">Prev</button></li> }

													{
														payload.data.pages.map((page, index) => {
															return (
																
																<li key={index} className={`page-item ${page === '...' ? 'disabled' : '' } ${page === payload.data.current_page ? 'active' : '' }`}><button onClick={this.handleClickPage.bind(null, page)} className="page-link">{page}</button></li>
																
															)
														})
													}

													{ payload.data.current_page < payload.data.last_page && <li key="next" className="page-item"><button onClick={this.handleClickPage.bind(null, payload.data.current_page + 1)} className="page-link">Next</button></li> }


												</ul>
											</nav>
										)
									}
								</div>
								<div className="col-md-2 text-right">
									<div className="form-group">
										<label className="control-label">Showing per page </label>
										<select
											defaultValue="10"
											id="perpage"
											className="form-control custom-select"
											onChange={this.hanldeChangePage}
										>
											<option value="10">10</option>
											<option value="20">20</option>
											<option value="50">50</option>
											<option value="100">100</option>
										</select>
									</div>
								</div>
							</div>
						</div>
					</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		);
	}
}



const mapStateToProps = (state) => {
    return {
        ...state,
        payload: state.qrcode.payload,
        error: state.qrcode.error,
		fetching: state.qrcode.fetching,
		message: state.qrcode.message,
		saved: state.qrcode.saved,
		isDeleted: state.qrcode.isDeleted
    }
}

const mapDispatchToProps = (dispatch) => {
	return {
		fetchQrcode: (filter) => dispatch(fetchQrcode(filter)),
		deleteQrcode: (id) => dispatch(deleteQrcode(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withToastManager(Qr));
