import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  ListGroup,
  ListGroupItem,
  Form,
  CardHeader,
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { appName, url } from "../global";
import { Helmet } from "react-helmet";
import { Link, Redirect } from "react-router-dom";
import { withToastManager } from "react-toast-notifications";
import Loading from "react-loading-bar";
import Error500 from "./Error500";
import Error403 from "./Error403";
import Axios from "axios";
import { connect } from "react-redux";
import { saveKanban } from "../store/actions/kanbanActions";
import QrReader from "react-qr-reader";
import ScrollToTop from "../components/layout/ScrollToTop";
import { onChange, scan } from "../store/actions/receivingActions";

class ScanReceiving extends React.Component {
  state = {
    qrmodal: false,
  };

  handleKeyDown = (e) => {
    const { scan } = this.props;
    if (e.key === "Enter") {
      scan(e.target.value);
    }
  };

  handleCloseModal = () => {
    this.setState({
      ...this.state,
      qrmodal: false,
    });
  };

  handleScan = (data) => {
    if (data) {
      this.props.scan(data);

      this.setState({
        qrmodal: false,
      });
    }
  };

  handleError = (err) => {
    console.error(err);
  };

  handleChange = (value) => {
    this.props.onChange(value);
  };

  handleOpenQr = () => {
    this.setState({
      ...this.state,
      qrmodal: true,
    });
  };

  render() {
    const { fetching, error, data, label } = this.props;
    const { qrmodal } = this.state;
    console.log(error && error.data.errors.lot_id_coil);

    if (!sessionStorage.getItem("token")) return <Redirect to="/login" />;
    if (error && error.status === 500)
      return <Error500 message={error.data.message} />;
    if (error && error.status === 403)
      return <Error403 message={error.data.message} />;
    return (
      <Container fluid className="main-content-container px-4">
        <Loading show={fetching} color="blue" showSpinner={false} />
        <Helmet>
          <title>Scan Receiving | {appName} </title>
        </Helmet>
        <Row noGutters className="page-header py-4">
          <div className="col-md-8">
            <PageTitle sm="4" title="Scan Receiving" className="text-sm-left" />
          </div>
          <div className="col-md-4 text-right">
            <Link className="btn btn-secondary" to="/receiving">
              Back
            </Link>
          </div>
        </Row>
        <Row>
          {qrmodal && (
            <ScrollToTop>
              <div className="messagebox msgbox-qr">
                <div className="modal-qr">
                  <button
                    onClick={this.handleCloseModal}
                    className="btn btn-default btn-cicle btn-lg p-0 pull-right"
                  >
                    <i className="mdi mdi-close"></i>
                  </button>
                  <QrReader
                    delay={300}
                    onError={this.handleError}
                    onScan={this.handleScan}
                    style={{ width: "100%" }}
                    // facingMode={cameramode}
                  />
                </div>
              </div>
              <div className="backdrop"></div>
            </ScrollToTop>
          )}

          <Col>
            <Card small className="mb-4">
              <CardBody className="p-0 pb-3">
                <div className="col-md-12 mt-3">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="control-label">Scan QR Here</label>
                        <div className="input-group mb-3">
                          <input
                            autoFocus
                            onKeyDown={this.handleKeyDown}
                            value={label}
                            type="text"
                            className={`form-control form-control-lg ${error &&
                              error.data.errors.lot_id_coil &&
                              "is-invalid"}`}
                            placeholder="Scan QR Here"
                            onChange={(e) => this.handleChange(e.target.value)}
                          />
                          <div className="input-group-prepend">
                            <button
                              className="btn btn-outline-secondary"
                              type="button"
                              id="button-addon1"
                              onClick={this.handleOpenQr}
                            >
                              <i className="mdi mdi-camera mdi-24px"></i>
                            </button>
                          </div>
                          {error && error.data.errors.lot_id_coil && (
                            <div className="invalid-feedback">
                              {error.data.errors.lot_id_coil[0]}
                            </div>
                          )}
                        </div>
                      </div>

                      <hr />
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
            {data && (
              <Col lg="12" className="p-0">
                <Card small className="mb-4">
                  <CardHeader className="border-bottom">
                    <h6 className="m-0">Details Info</h6>
                  </CardHeader>
                  <ListGroup flush>
                    <ListGroupItem>
                      <Row>
                        <Col>
                          <Form>
                            <Row form>
                              <Col md="6" className="form-group">
                                <label htmlFor="feFirstName">
                                  Customer Name
                                </label>
                                <p className="help-block">
                                  {data && data.customer_name}
                                </p>
                              </Col>
                              <Col md="6" className="form-group">
                                <label htmlFor="feFirstName">Lot ID Coil</label>
                                <p className="help-block">
                                  {data && data.lot_id_coil}
                                </p>
                              </Col>
                              <Col md="6" className="form-group">
                                <label htmlFor="feFirstName">Part Name</label>
                                <p className="help-block">
                                  {data && data.part_name}
                                </p>
                              </Col>
                              <Col md="6" className="form-group">
                                <label htmlFor="feFirstName">Part No</label>
                                <p className="help-block">
                                  {data && data.part_no}
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="6" className="form-group">
                                <label htmlFor="feFirstName">Qty/Pack</label>
                                <p className="help-block">{data && data.qty}</p>
                              </Col>
                              <Col md="6" className="form-group">
                                <label htmlFor="feFirstName">Size</label>
                                <p className="help-block">
                                  {data &&
                                    `${data.size_1}x${data.size_2}x${data.size_3}`}
                                </p>
                              </Col>
                              <Col md="6" className="form-group">
                                <label htmlFor="feFirstName">Spec</label>
                                <p className="help-block">
                                  {data && data.spec}
                                </p>
                              </Col>
                              <Col md="6" className="form-group">
                                <label htmlFor="feFirstName">Weight</label>
                                <p className="help-block">
                                  {data && data.weight} Kg(s)
                                </p>
                              </Col>
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </ListGroupItem>
                  </ListGroup>
                </Card>
              </Col>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    fetching: state.loading.fetching,
    error: state.receiving.error,
    data: state.receiving.data,
    label: state.receiving.label,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    scan: (data) => dispatch(scan(data)),
    onChange: (data) => dispatch(onChange(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withToastManager(ScanReceiving));
