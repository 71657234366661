// import { message } from 'antd';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { url } from '../global';
import PulseLoader from "react-spinners/PulseLoader";
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import { login } from '../store/actions/authActions';

const useQuery = () => {
    return new URLSearchParams(window.location.search);
}

function Sso(props) {
    let [loading, setLoading] = useState(true);
    const [urlSso, setUrlSso] = useState("");

    let query = useQuery();
    useEffect(() => {
        getDataApiSSO()
    }, [])

    const getDataApiSSO = async () => {
        await Axios.get(`${url}/settings-sso/find`, {
            params: {
                variable: "api_sso",
            },
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        })
            .then((res) => {
                console.log(res)
                if (res.data.type === "error") {
                    // message.error(res.data.msg);
                    props.toastManager.add(res.data.msg, {
                        appearance: "error",
                        autoDismiss: true,
                    });
                } else {
                    setUrlSso(res.data.data[0].name);
                    getUser(res.data.data[0].name);
                }
            })
            .catch((err) => {
                if (err.response) {
                    props.toastManager.add(err.response.data.message, {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    if (err.response.status === 401) {
                        sessionStorage.removeItem("token");
                    }
                }
            });
    };

    const getUser = async (apiUrl) => {
        const user = await Axios.get(`${apiUrl}/check-token`, {
            params: {
                token: query.get('token')
            }
        }).then(res => {
            return res.data.user
        })

        loginSSO(user)
    }

    const loginSSO = (user) => {
        Axios.post(`${url}/login-sso`, {
            username: user.username,
            password: user.password,
            email: user.email,
            full_name: user.full_name,
            role_user_destination_id: user.role_user_destination_id,
            role_user_destination: user.role_user_destination,
            role_id: user.role_id,
            role_name: user.role_name,
            npk: user.npk,
            user_type: user.user_type
        })
            .then((res) => {
                sessionStorage.setItem('token', res.data.token);
                sessionStorage.setItem('name', res.data.data.name);
                sessionStorage.setItem('_id', res.data.data._id);
                sessionStorage.setItem('photo', res.data.data.picture_url);

                if (res.data.token && res.data.data.role !== null) {
                    if (res.data.data.role.name === 'Admin') {
                        props.history.push('/receiving');
                    } else {
                        props.history.push(res.data.redirect);
                    }
                } else {
                    props.toastManager.add(res.data.message, {
                        appearance: "error",
                        autoDismiss: true,
                        duration: 5000,
                    });
                    setTimeout(() => {
                        props.history.push('/login')
                    }, 5000)
                }
            })
            .catch((err) => {
                if (err.response) {
                    props.toastManager.add(err.response.data.message, {
                        appearance: "error",
                        autoDismiss: true,
                        duration: 5000,
                    });
                    setTimeout(() => {
                        props.history.push('/login')
                    }, 5000)
                }
            });
    }
    return (
        <div style={stylewrap}>
            <div className="sweet-loading" style={loader}>
                <PulseLoader loading={loading} size={20} color={"#03a000"} style={{ opacity: '100% !important' }} />
            </div>
        </div>
    )

}
const stylewrap = {
    textAlign: "center",
    position: "fixed",
    top: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#ffffff",
    zIndex: 1000,
    opacity: "0.5"
}
const loader = {
    position: "relative",
    top: "15rem"
}

const mapDispatchToProps = (dispatch) => {
    return {
        login: (credentials) => dispatch(login(credentials))
    }
}

export default connect(mapDispatchToProps)(withToastManager(Sso));
