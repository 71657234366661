import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout, BlankLayout, EmptyLayout } from "./layouts";
import Qr from "./views/Qr";
import Login from "./views/Login";
import ForgotPassword from "./views/ForgotPassword";
import ResetPassword from "./views/ResetPassword";
import Supplier from "./views/Supplier";
import AddSupplier from "./views/AddSupplier";
import EditSupplier from "./views/EditSupplier";
import EditQr from "./views/EditQr";
import Menu from "./views/Menu";
import Role from "./views/Role";
import AddRole from "./views/AddRole";
import EditRole from "./views/EditRole";
import Permission from "./views/Permission";
import AddPermission from "./views/AddPermission";
import EditPermission from "./views/EditPermission";
import User from "./views/User";
import AddUser from "./views/AddUser";
import EditUser from "./views/EditUser";
import ViewUser from "./views/ViewUser";
import Plant from "./views/Plant";
import AddPlant from "./views/AddPlant";
import EditPlant from "./views/EditPlant";
import Customer from "./views/Customer";
import AddCustomer from "./views/AddCustomer";
import EditCustomer from "./views/EditCustomer";
import Part from "./views/Part";
import Warehouse from "./views/Warehouse";
import AddWarehouse from "./views/AddWarehouse";
import EditWarehouse from "./views/EditWarehouse";
import Rack from "./views/Rack";
import AddRack from "./views/AddRack";
import EditRack from "./views/EditRack";
import AddPart from "./views/AddPart";
import EditPart from "./views/EditPart";
import Cycle from "./views/Cycle";
import AddCycle from "./views/AddCycle";
import EditCycle from "./views/EditCycle";
import Expedition from "./views/Expedition";
import AddExpedition from "./views/AddExpedition";
import EditExpedition from "./views/EditExpedition";
import Dashboard from "./views/Dashboard";
import ScanWarehouse from "./views/ScanWarehouse";
import Kanban from "./views/Kanban";
import ScanDN from "./views/ScanDN";
import DeliveryNote from "./views/DeliveryNote";
import Dashboard2 from "./views/Dashboard2";
import ScanRack from "./views/ScanRack";
import ScanReceiving from "./views/ScanReceiving";
import Receiving from "./views/Receiving";
import LabelProd from "./views/LabelProd";
import ScanLabelProd from "./views/ScanLabelProd";
import EditLabelProd from "./views/EditLabelProd";
import PreparationDelivery from "./views/PreparationDelivery";
import ScanPreparationDelivery from "./views/ScanPreparationDelivery";
import TraceAHM from "./views/TraceAhm";
import Sso from "./views/Sso";

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/trace-ahm" />,
  },
  {
    path: "/qr-code",
    layout: DefaultLayout,
    component: Qr,
    exact: true,
  },
  {
    path: "/qr-code/edit/:id",
    layout: DefaultLayout,
    component: EditQr,
  },
  {
    path: "/login",
    layout: BlankLayout,
    component: Login,
  },
  {
    path: "/forgot-password",
    layout: BlankLayout,
    component: ForgotPassword,
  },
  {
    path: "/reset-password",
    layout: BlankLayout,
    component: ResetPassword,
  },
  {
    path: "/sso",
    layout: BlankLayout,
    component: Sso,
  },

  {
    path: "/supplier",
    layout: DefaultLayout,
    component: Supplier,
    exact: true,
  },
  {
    path: "/supplier/create",
    layout: DefaultLayout,
    component: AddSupplier,
  },
  {
    path: "/supplier/edit/:id",
    layout: DefaultLayout,
    component: EditSupplier,
  },
  {
    path: "/menu",
    layout: DefaultLayout,
    component: Menu,
  },
  {
    path: "/role",
    layout: DefaultLayout,
    component: Role,
    exact: true,
  },
  {
    path: "/role/create",
    layout: DefaultLayout,
    component: AddRole,
    exact: true,
  },
  {
    path: "/role/edit/:id",
    layout: DefaultLayout,
    component: EditRole,
    exact: true,
  },
  {
    path: "/permission",
    layout: DefaultLayout,
    component: Permission,
    exact: true,
  },
  {
    path: "/permission/create",
    layout: DefaultLayout,
    component: AddPermission,
    exact: true,
  },
  {
    path: "/permission/edit/:id",
    layout: DefaultLayout,
    component: EditPermission,
    exact: true,
  },
  {
    path: "/user",
    layout: DefaultLayout,
    component: User,
    exact: true,
  },
  {
    path: "/user/create",
    layout: DefaultLayout,
    component: AddUser,
    exact: true,
  },
  {
    path: "/user/edit/:id",
    layout: DefaultLayout,
    component: EditUser,
    exact: true,
  },
  {
    path: "/user/view/:id",
    layout: DefaultLayout,
    component: ViewUser,
    exact: true,
  },
  {
    path: "/plant",
    layout: DefaultLayout,
    component: Plant,
    exact: true,
  },
  {
    path: "/plant/create",
    layout: DefaultLayout,
    component: AddPlant,
    exact: true,
  },
  {
    path: "/plant/edit/:id",
    layout: DefaultLayout,
    component: EditPlant,
    exact: true,
  },
  {
    path: "/customer",
    layout: DefaultLayout,
    component: Customer,
    exact: true,
  },
  {
    path: "/customer/create",
    layout: DefaultLayout,
    component: AddCustomer,
    exact: true,
  },
  {
    path: "/customer/edit/:id",
    layout: DefaultLayout,
    component: EditCustomer,
    exact: true,
  },
  {
    path: "/part",
    layout: DefaultLayout,
    component: Part,
    exact: true,
  },
  {
    path: "/part/create",
    layout: DefaultLayout,
    component: AddPart,
    exact: true,
  },
  {
    path: "/part/edit/:id",
    layout: DefaultLayout,
    component: EditPart,
    exact: true,
  },
  {
    path: "/warehouse",
    layout: DefaultLayout,
    component: Warehouse,
    exact: true,
  },
  {
    path: "/warehouse/create",
    layout: DefaultLayout,
    component: AddWarehouse,
    exact: true,
  },
  {
    path: "/warehouse/edit/:id",
    layout: DefaultLayout,
    component: EditWarehouse,
    exact: true,
  },
  {
    path: "/rack",
    layout: DefaultLayout,
    component: Rack,
    exact: true,
  },
  {
    path: "/rack/create",
    layout: DefaultLayout,
    component: AddRack,
    exact: true,
  },
  {
    path: "/rack/edit/:id",
    layout: DefaultLayout,
    component: EditRack,
    exact: true,
  },
  {
    path: "/cycle",
    layout: DefaultLayout,
    component: Cycle,
    exact: true,
  },
  {
    path: "/cycle/create",
    layout: DefaultLayout,
    component: AddCycle,
    exact: true,
  },
  {
    path: "/cycle/edit/:id",
    layout: DefaultLayout,
    component: EditCycle,
    exact: true,
  },
  {
    path: "/expedition",
    layout: DefaultLayout,
    component: Expedition,
    exact: true,
  },
  {
    path: "/expedition/create",
    layout: DefaultLayout,
    component: AddExpedition,
    exact: true,
  },
  {
    path: "/expedition/edit/:id",
    layout: DefaultLayout,
    component: EditExpedition,
    exact: true,
  },
  {
    path: "/dashboard",
    layout: EmptyLayout,
    component: Dashboard,
    exact: true,
  },
  {
    path: "/scan-warehouse",
    layout: DefaultLayout,
    component: ScanWarehouse,
    exact: true,
  },
  {
    path: "/kanban",
    layout: DefaultLayout,
    component: Kanban,
    exact: true,
  },
  {
    path: "/scan-dn",
    layout: DefaultLayout,
    component: ScanDN,
    exact: true,
  },
  {
    path: "/scan-rack",
    layout: DefaultLayout,
    component: ScanRack,
    exact: true,
  },
  {
    path: "/delivery-note",
    layout: DefaultLayout,
    component: DeliveryNote,
    exact: true,
  },
  {
    path: "/dashboard-2",
    layout: EmptyLayout,
    component: Dashboard2,
  },
  {
    path: "/receiving",
    layout: DefaultLayout,
    component: Receiving,
    exact: true,
  },
  {
    path: "/receiving/scan",
    layout: DefaultLayout,
    component: ScanReceiving,
    exact: true,
  },
  {
    path: "/label-prod",
    layout: DefaultLayout,
    component: LabelProd,
    exact: true,
  },
  {
    path: "/label-prod/scan",
    layout: DefaultLayout,
    component: ScanLabelProd,
    exact: true,
  },
  {
    path: "/label-prod/edit/:id",
    layout: DefaultLayout,
    component: EditLabelProd,
    exact: true,
  },
  {
    path: "/preparation-delivery",
    layout: DefaultLayout,
    component: PreparationDelivery,
    exact: true,
  },
  {
    path: "/preparation-delivery/scan",
    layout: DefaultLayout,
    component: ScanPreparationDelivery,
    exact: true,
  },
  {
    path: "/trace-ahm",
    layout: DefaultLayout,
    component: TraceAHM,
    exact: true,
  },
];
