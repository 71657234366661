import React from 'react';
import { Container, Row, Col, Card, CardBody } from 'shards-react';
import PageTitle from '../components/common/PageTitle';
import { appName, customerStyles, url, socketurl } from '../global';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { withToastManager } from 'react-toast-notifications';
import Loading from 'react-loading-bar';
import Error500 from './Error500';
import Error403 from './Error403';
import AsyncSelect from 'react-select/async';
import Axios from 'axios';
import socketIOClient from "socket.io-client";

class ScanWarehouse extends React.Component {

    state = {
        code: '',
        rack: '',
        warehouse_name: null,
        warehouse_id: null,
        kanban_id: null,
        fetching: false,
        error: null,
        payload: []
    };
    
    handleChange = (e) => {
		this.setState({
			...this.state,
			[e.target.id]: e.target.value
		});
    }
    
    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.getKanban(this.state.rack)
        }
    }

    getKanban = (rack) => {
        Axios.get(`${url}/scan-warehouse/${rack}`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            // console.log(res)
            this.saveKanban(res.data.data._id)

            this.setState({
                ...this.state,
                payload: res.data.data.details,
                error: null,
                rack: ''
            })

        }).catch(err => {
            console.log(err.response)
            this.setState({
                ...this.state,
                error: err.response,
                payload: []
            })
        })
    }

    handleChangeSelect = (value, e) => {
        this.setState({
			...this.state,
            [`${e.name}_id`]: value ? value.value : null,
            [`${e.name}_name`]: value ? value.label : null,
		});
    }

    saveKanban = (id) => {
        Axios.post(`${url}/scan-warehouse`,{
                    kanban_id: id,
                    warehouse_id: this.state.warehouse_id        
                }, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`
                }
        }).then(res => {
            const { toastManager } = this.props;
            toastManager.add(res.data.message, {
                appearance: 'success',
                autoDismiss: true
            });

            const socket = socketIOClient(socketurl);
            socket.emit('update part')
            
        }).catch(err => {
            const { toastManager } = this.props;
            toastManager.add(err.response.data.message, {
                appearance: 'error',
                autoDismiss: true
            });
        })
    }

	render() {  
        const { rack, warehouse_id, warehouse_name, error, fetching, payload } = this.state
        if (!sessionStorage.getItem('token')) return <Redirect to="/login" />
        if (error && error.status === 500) return <Error500 message={error.data.message} />
        if (error && error.status === 403) return <Error403 message={error.data.message} />
        return (
         
			<Container fluid className="main-content-container px-4">
                <Loading
						show={fetching}
						color="blue"
						showSpinner={false}
						/>
				<Helmet>
					<title>Scan Warehouse | {appName} </title>
				</Helmet>
				<Row noGutters className="page-header py-4">
                <div className="col-md-8">
					    <PageTitle sm="12" title="Scan Warehouse" className="text-sm-left" />
                    </div>
				</Row>
				<Row>
					<Col>
						<Card small className="mb-4">
							    <CardBody className="p-0 pb-3">
                                    <div className="col-md-12 mt-3">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="" className="control-label">Warehouse</label>
                                                    <AsyncSelect 
                                                        value={ warehouse_id && { label: warehouse_name, value: warehouse_id }} 
                                                        isClearable={true} 
                                                        className={error && error.data.errors.warehouse_id && 'is-invalid-select'} 
                                                        styles={customerStyles} loadOptions={warehouseOptions} 
                                                        placeholder="Type to search" 
                                                        onChange={this.handleChangeSelect} 
                                                        name="warehouse"
                                                        cacheOptions
                                                        defaultOptions
                                                        />
                                                    { 
                                                        error && error.data.errors.warehouse_id && <small className="text-danger">{ error.data.errors.warehouse_id[0] }</small>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="" className="control-label">Rack</label>
                                                    <input onKeyDown={this.handleKeyDown} value={rack} onChange={this.handleChange} id="rack" type="text" className={`form-control form-control-lg ${ error && error.data.errors.rack_id && 'is-invalid '}`} placeholder="Scan rack code here" />
                                                    { 
                                                        error && error.data.errors.rack_id && <div className="invalid-feedback">{ error.data.errors.rack_id[0] }</div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-4 mb-5">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Part #</th>
                                                    <th>Part Name</th>
                                                    <th>Qty</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    payload && payload.length > 0 ? payload.map(part => {
                                                        return <tr key={ part._id }>
                                                            <td>{ part.part_no }</td>
                                                            <td>{ part.part_name }</td>
                                                            <td>{ part.qty }</td>
                                                        </tr>
                                                    }) : (

                                                        <tr>
                                                            <td colSpan="3" className="text-center">No Data</td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
            
		);
	}
}

const appendWarehouse = filteredOptions => {
    return [
      ...filteredOptions
    ];
};

const filterWarehouse = (warehouses) => {
    const options = warehouses.map(rack => {
        return { label: rack.name, value: rack._id }
    })
 
    return appendWarehouse(options);
 };
   
 const warehouseOptions = (inputValue, callback) => {
     Axios.get(`${url}/warehouse/list`, {
         params: {
             name: inputValue,
         }, 
         headers: {
             Authorization: `Bearer ${sessionStorage.getItem('token')}`
         }
     }).then(response => {
         callback(filterWarehouse(response.data.data));
     });
 }
 


export default withToastManager(ScanWarehouse);